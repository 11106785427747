import React from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import Image from './fields/image';
import Html from './fields/html';
import Gallery from './fields/gallery';
import Section from './fields/section';
import Select from './fields/select';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )
const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang,
    children
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}>
            {children}
        </Select>
    )
const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang

}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )

const renderHtmlField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang

}) => (

        <Html
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )

const renderImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Image
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderGalleryField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Gallery
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            lang={lang}
            multilang={multilang}
            {...input}
        />
    )

const renderSectionField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Section
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            lang={lang}
            multilang={multilang}
            {...input}
        />
    )



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting, events } = props;


    return (
        <form onSubmit={handleSubmit}>

            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12" className="input-wrap">
                                {
                                    events ?

                                        <Field
                                            name="event"
                                            component={renderSelectField}
                                            label={"Dogadjaj"}
                                            placeholder=""
                                            multilang
                                            lang={props.lang}
                                        >
                                            {
                                                events ? events.map((item, idx) => {
                                                    console.log("ITEM " + idx + " : " + item.title)
                                                    return (
                                                        <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                    )
                                                }) : null
                                            }

                                        </Field>
                                        : null
                                }
                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="description"
                                    component={renderTextareaField}
                                    label={"Opis"}
                                    placeholder=""
                                    multilang
                                    lang={props.lang}
                                ></Field>
                            </Col>
                            <Col lg="12">
                                <Field
                                    name="images"
                                    component={renderGalleryField}
                                    placeholder=""
                                    multilang
                                    lang={props.lang}
                                ></Field>
                            </Col>

                            <Col lg="12">
                                <button className="button">Spremi</button>
                            </Col>

                        </Row>
                    </Container>
                </Col>


            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)