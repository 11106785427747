import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Page from '../containers/page';

import moment from 'moment';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

class UserPreviewPage extends Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);

        this.state = {
            items: {}
        };
    }
    delete() {
        if (!localStorage.token) {
            return;
        }
        if (this.props[0].match.params.id) {
            fetch('https://api.freedommusic.rs/admin/users/delete/' + this.props[0].match.params.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                },
            }).then((res) => this.props[0].history.push('/users'))
        }

    }

    componentDidMount() {
        if (!localStorage.token) {
            return;
        }
        fetch('https://api.freedommusic.rs/admin/users', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            for (let i = 0; i < result.length; i++) {
                if (result[i]._id == this.props[0].match.params.id) {
                    this.setState({
                        items: result[i]
                    })
                }
            }


        })

        if (this.props[0].match.params.id) {
            fetch('https://api.freedommusic.rs/admin/user/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                }
            }).then((res) => res.json()).then((result) => {
                console.log(result);
                this.setState(
                    result
                )
            })

        }

    }

    render() {

        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                            <div className="top-row">
                                <h3>USER DETAIL</h3>
                                <button className="button2" onClick={() => this.delete()}>DELETE</button>
                            </div>
                        </Col>


                    </Row>

                    <Row>
                        <Col lg="12" >
                            <Container fluid className="form-box contact-box">
                                <Row>
                                    <Col lg="8">
                                        <Row>
                                            <Col lg="6">
                                                <label>NAME</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{this.state.name}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>EMAIL</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{this.state.email}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>PHONE NUMBER</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{this.state.phoneNumber}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>SEX</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{this.state.sex}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>USERNAME</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{this.state.username}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>BIRTHDAY</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{moment.unix(this.state.birthday).format('DD.MM.YYYY')}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>REGISTER TIME</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{moment.unix(this.state.registerTimestamp).format('DD.MM.YYYY HH:mm')}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>EMAIL VERIFIED</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{this.state.emailVerified ? 'Yes' : 'No'}</p>
                                            </Col>
                                            <Col lg="6">
                                                <label>ACCOUNT VERIFIED</label>
                                            </Col>
                                            <Col lg="6">
                                                <p>{this.state.accountVeified ? 'Yes' : 'No'}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4">
                                        <div className="profile-image">
                                            <img src={this.state.image} />
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                        </Col>
                    </Row>

                </Container>


            </div>
        );
    }
}

export default Page(UserPreviewPage);