import React from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import Image from './fields/image';
import Html from './fields/html';
import Gallery from './fields/gallery';
import Section from './fields/section';
import Date from './fields/date';
import Select from './fields/select';
import moment from 'moment';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang,
    children
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}>
            {children}
        </Select>
    )


const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )
const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang

}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )
const renderDateField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (
        <Date
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            type={type}
            error={touched && error}
            {...input}
        />
    )

const renderHtmlField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang

}) => (

        <Html
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )

const renderImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Image
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderGalleryField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Gallery
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            lang={lang}
            multilang={multilang}
            {...input}
        />
    )
const renderSectionField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Section
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            lang={lang}
            multilang={multilang}
            {...input}
        />
    )

const form = (props) => {
    const { handleSubmit, pristine, reset, submitting, events } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Izvodjac</h3>
                                <h6 className="subtitle">Unesite informacije</h6>
                            </Col>

                            <Col lg="9">
                                <Row>
                                    <Col lg="12" className="input-wrap">
                                        <Field
                                            name="name"
                                            component={renderTextField}
                                            label={"Ime i prezime"}
                                            placeholder=""
                                            multilang
                                            lang={props.lang}
                                        ></Field>
                                    </Col>


                                    <Col lg="12" className="input-wrap">
                                        <Field
                                            name="birth"
                                            component={renderTextField}
                                            label={"Informacije o rodjenju"}
                                            placeholder=""
                                            multilang
                                            lang={props.lang}
                                        ></Field>
                                    </Col>
                                    <Col lg="6" className="input-wrap">
                                        <Field
                                            name="type"
                                            component={renderTextField}
                                            label={"Zanr"}
                                            placeholder=""
                                            multilang
                                            lang={props.lang}

                                        ></Field>


                                    </Col>

                                    <Col lg="6" className="input-wrap">
                                        <Field
                                            name="publisher"
                                            component={renderTextField}
                                            label={"Diskografske kuce"}
                                            placeholder=""
                                            multilang
                                            lang={props.lang}

                                        ></Field>
                                    </Col>
                                    <Col lg="12" className="input-wrap">
                                        <Field
                                            name="description"
                                            component={renderTextField}
                                            label={"Kratak opis"}
                                            placeholder=""
                                            multilang
                                            lang={props.lang}

                                        ></Field>
                                    </Col>

                                </Row>
                            </Col>
                            <Col lg="3" className="input-wrap">
                                <Field
                                    name="image"
                                    component={renderImageField}
                                    placeholder=""
                                    lang={props.lang}
                                ></Field>
                            </Col>
                            <Col lg="6" className="input-wrap">
                                <Field
                                    name="buttonLink1"
                                    component={renderTextField}
                                    label={"Instagram link"}
                                    placeholder=""
                                    multilang
                                    lang={props.lang}
                                ></Field>
                            </Col>
                            <Col lg="6" className="input-wrap">
                                <Field
                                    name="buttonLink2"
                                    component={renderTextField}
                                    label={"Facebok link"}
                                    placeholder=""
                                    multilang
                                    lang={props.lang}
                                ></Field>
                            </Col>
                            <Col lg="12">
                                <Field
                                    name="content"
                                    component={renderSectionField}
                                    placeholder=""
                                    multilang
                                    lang={props.lang}
                                ></Field>
                            </Col>
                            
                        </Row>
                        <Row>
                            {/* <Col lg="12">
                                <h3 className="title">Nastupi</h3>
                                <h6 className="subtitle">Unesite informacije</h6>
                            </Col> */}
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #1</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[0].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>

                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[0].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[0].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[0].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #2</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[1].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[1].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[1].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[1].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #3</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[2].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[2].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[2].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[2].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #4</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[3].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[3].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[3].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[3].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #5</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[4].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[4].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[4].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[4].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #6</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[5].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[5].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[5].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[5].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #7</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[6].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[6].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[6].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[6].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #8</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[7].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[7].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[7].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[7].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #9</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[8].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[8].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[8].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[8].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>
                            <Col lg="3" className="perfomance">
                                <Col lg="12">
                                    <h6 className="title">Nastup #10</h6>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    {
                                        events ?

                                            <Field
                                                name="performance[9].eventId"
                                                component={renderSelectField}
                                                label={"Dogadjaj"}
                                                placeholder=""
                                                multilang
                                                lang={props.lang}
                                            >
                                                {
                                                    events ? events.map((item, idx) => {
                                                        // console.log("ITEM " + idx + " : " + item.title)
                                                        return (
                                                            <option value={item._id}>{item.title.ba} {moment.unix(item.date).format('DD.MM.YYYY')}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Field>
                                            : null
                                    }
                                </Col>
                                {/* <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[9].date"
                                        component={renderDateField}
                                        label={"Datum"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[9].event"
                                        component={renderTextareaField}
                                        label={"Naslov"}
                                        placeholder=""
                                        multilang
                                        lang={props.lang}
                                    ></Field>
                                </Col> */}
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="performance[9].image"
                                        component={renderImageField}

                                    ></Field>
                                </Col>
                            </Col>

                        </Row>
                    </Container>
                </Col>









                <Col lg="12">
                    <button className="button">Spremi</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
