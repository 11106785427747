import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import visitIcon from '../assets/svg/visit.svg';

import editIcon from '../assets/svg/edit.svg';
import deleteIcon from '../assets/svg/delete.svg';
import DeleteModal from '../components/deleteModal'

import {
    Container,
    Row,
    Col,
    Input,
    FormGroup,
    Label
} from 'reactstrap';


function msToTime(duration) {
    var milliseconds = (duration % 1000),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    // return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    return minutes + ":" + seconds + "." + milliseconds;
}

class GamesResultsPage extends Component {
    constructor(props) {
        super(props);
        this.fetchPages = this.fetchPages.bind(this);


        this.state = {
            items: [],
            deleteAll: false,
            deleteItem: null,
            order: 1
        };
    }

    componentDidMount() {
        this.fetchPages();


    }
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.items) != JSON.stringify(this.state.items)) {
            this.checkSort()
        }
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
            this.fetchPages();
        }
    }

    fetchPages() {
        if (!localStorage.token) {
            return;
        }

        let alias = this.props[0].match.params.alias ? this.props[0].match.params.alias : null;

        fetch('https://api.freedommusic.rs/admin/games/allResults', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ alias: alias })
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })


    }
    deleteResultItem = (id) => {
        if (!localStorage.token) {
            return;
        }


        fetch('https://api.freedommusic.rs/admin/games/results/delete/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`

            },
        }).then((res) => this.fetchPages())

    }
    deleteAll = () => {
        if (!localStorage.token) {
            return;
        }
        let alias = this.props[0].match.params.alias ? this.props[0].match.params.alias : null;

        fetch('https://api.freedommusic.rs/admin/games/results/all/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`

            },
            body: JSON.stringify({ alias })
        }).then((res) => this.fetchPages())

    }
    checkSort = () => {
        if (this.state.items && this.state.order) {
            let items = this.state.items;
            if (this.state.order == 1) {
                items.sort((a, b) => a.points > b.points ? 1 : -1);
            } else if (this.state.order == -1) {
                items.sort((a, b) => a.points < b.points ? 1 : -1);
            }

            this.setState({ items })

        }
    }

    render() {
        let alias = this.props[0].match.params.alias ? this.props[0].match.params.alias : null;

        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid className="table">

                    <Row className="page-title">
                        <Col lg="12">
                            <div className="top-row">
                                <h3>Rezultati {alias ? `: ${alias}` : null}</h3>
                                <div className='top-right-wrap'>
                                    <FormGroup className="sort-wrapp">
                                        <Label>Sortiranje</Label>
                                        <Input type='select' value={this.state.order} onChange={(e) => {
                                            this.setState({ order: e.target.value }, () => this.checkSort())
                                        }}>
                                            <option value={1}>{'Uzlazno'}</option>
                                            <option value={-1}>{'Silazno'}</option>
                                        </Input>
                                    </FormGroup>
                                    <button className="button2" onClick={() => this.setState({ deleteAll: true })}>OBRIŠI SVE</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="table-head">
                        <Col lg="3">
                            <span className="name">IGRICA</span>
                        </Col>
                        <Col lg="3">
                            <span className="name">USERNAME</span>
                        </Col>
                        <Col lg="3">
                            <span className="name">
                                BODOVI
                            </span>
                        </Col>

                        <Col lg="3" className="actions">

                            <span className="name">OPCIJE</span>
                        </Col>

                    </Row>
                    {
                        this.state.items && this.state.items.map((item, idx) => {
                            return (
                                <Row className="table-row" key={idx}>
                                    <Col lg="3">
                                        <span className="value" style={item.alias ? { cursor: 'pointer' } : {}} onClick={() => {
                                            if (item.alias)
                                                this.props[0].history.push(`/games/results/game/${item.alias}`)
                                        }}>{item.game}</span>

                                    </Col>
                                    <Col lg="3">
                                        <span className="value">{item.username}</span>
                                    </Col>
                                    <Col lg="3">
                                        {/* <span className="value">{[String(item.points).slice(0, -3), ".", String(item.points).slice(-3)].join('')}</span> */}
                                        <span className='value'>
                                            {/* {item.points ? msToTime(item.points) : ''} */}
                                            {item.points}
                                        </span>
                                    </Col>

                                    <Col lg="3" className="actions">
                                        <button onClick={() => this.setState({ deleteItem: item })}><Isvg src={deleteIcon} /></button>
                                    </Col>

                                </Row>

                            )
                        })
                    }
                    <DeleteModal
                        isOpen={this.state.deleteAll}
                        toggle={() => this.setState({ deleteAll: false })}
                        handler={() => { this.deleteAll(); this.setState({ deleteAll: false }) }}
                    >
                        Da li želite da obrišete <strong>sve rezultate</strong>?
                    </DeleteModal>
                    <DeleteModal
                        isOpen={this.state.deleteItem}
                        toggle={() => this.setState({ deleteItem: null })}
                        handler={() => { this.deleteResultItem(this.state.deleteItem._id); this.setState({ deleteItem: null }) }}
                    >
                        Da li želite da obrišete rezultat korisnika
                        <strong> {this.state.deleteItem && this.state.deleteItem.username}</strong>,
                        koji je ostvario <strong>{this.state.deleteItem && this.state.deleteItem.points} poena</strong>?
                    </DeleteModal>

                </Container>



            </div>
        );
    }
}

export default Page(GamesResultsPage);